import _ from 'lodash';

export const defaultQuestions = function (opts) {
  return [
    _.extend(
      {
        title: 'Driver Type',
        key: 'driverTypes',
        clientPortalHint:
          'Whether you want company drivers or owner operators, this is the spot to filter by driver type.',
        multi: true,
        store: 'object',
        options: [
          'companyDriver',
          'leasePurchase',
          'ownerOperator',
          'teamDriver',
          'interestTeamDriver',
        ],
      },

      _.get(opts, 'questions.driverTypes') || {}
    ),
    {
      title: 'Driver Type',
      key: 'driverAndJobType',
      multi: true,
      store: 'array',
      note: `Note: Drivers will only need to match one of the following`,
      options: [
        'soloCompany',
        'soloOwner',
        'soloLease',
        'soloInterestedInLease',
        'teamCompany',
        'teamOwner',
        'teamLease',
        'teamInterestedInLease',
        'interestedTeamCompany',
        'interestedTeamOwner',
        'interestedTeamLease',
        'interestedTeamInterestedInLease',
      ],
    },
    _.extend(
      {
        title: 'Job Type',
        key: 'jobTypes',
        multi: false,
        store: 'object',
        options: [
          'Over The Road',
          'Regional',
          'Local',
          'Dedicated Lane',
          'Dedicted Account',
          'Non-Driving',
        ],
      },
      _.get(opts, 'questions.jobTypes') || {}
    ),
    _.extend(
      {
        title: 'Trailer Type',
        key: 'trailerTypes',
        clientPortalHint:
          'Select the freight type your drivers will be hauling, making sure to include any that are applicable.',
        multi: true,
        store: 'object',
        note: `Note: Drivers will only need to match one of the following`,
        options: [
          'dryVan',
          'reefer',
          'flatbed',
          'tanker',
          'doubtrip',
          'intermodal',
          'hhg',
          'hazmat',
          'specialized',
          'hauler',
          'doublepups',
          'dryBulk',
        ],
      },
      _.get(opts, 'questions.trailerTypes') || {}
    ),
    _.extend(
      {
        title: 'All Applicable Experience Levels',
        key: 'experience',
        clientPortalHint:
          'Choose your company’s desired driver experience level.',
        multi: true,
        store: 'array',
        config: {
          selectAllAfter: true,
        },
        options: [
          'needtraining',
          'intraining',
          'graduatedtraining',
          '1-5_months',
          '6-11_months',
          '12-23_months',
          '2_years',
          '3_years',
          '4_years',
          '5+_years',
        ],
      },
      _.get(opts, 'questions.experience') || {}
    ),
    {
      title: 'CDL Requirement ',
      key: 'cdlRequirement',
      clientPortalHint:
        'Select the CDL-Requirement. If default is selected, we require a CDL-A if the job requires experience and is a driving job.',
      options: [
        {
          title: 'Default',
          value: null,
        },
        {
          title: 'CDL-A',
          value: 'cdl-a',
        },
        {
          title: 'CDL-B',
          value: 'cdl-b',
        },
        {
          title: 'None',
          value: 'none',
        },
      ],
    },
    _.extend(
      {
        title: 'Required Endorsements',
        key: 'endorsementTypes',
        clientPortalHint:
          'Need someone with special experience? Select any endorsements you’re looking for here.',
        note: `Note: If required endorsements are selected, <b>only</b> leads with endorsements will be sent.`,
        multi: true,
        store: 'object',
        options: ['hEndorsement', 'nEndorsement', 'tEndorsement'],
      },
      _.get(opts, 'questions.endorsementTypes')
    ),
    {
      title: 'Are leads required to have a Class A CDL?',
      key: 'cdlClassA',
      clientPortalHint:
        'Filter for drivers based on whether or not you want them to already have their CDL.',
      multi: false,
      options: [
        {
          title: 'Yes',
          value: true,
        },
        { title: 'No', value: false },
      ],
      conditionalQuestions: [
        {
          condition: answer => answer === false,
          key: 'cdlClassB',
          default: false, // for when the condition isn't met but this has been selected in the past
        },
      ],
    },
    {
      title: 'Are leads required to have a Class B CDL?',
      key: 'cdlClassB',
      clientPortalHint:
        'Filter for drivers based on whether or not you want them to already have their CDL B.',
      multi: false,
      options: [
        {
          title: 'Yes',
          value: true,
        },
        { title: 'No', value: false },
      ],
    },
    {
      title: 'Exclude leads enrolled in the SAP program?',
      key: 'excludeSapProgram',
      multi: false,
      options: [
        {
          title: 'Yes',
          value: true,
        },
        { title: 'No', value: false },
      ],
    },
    {
      title: 'Exclude leads that are not interested in OTR or Regional?',
      key: 'excludeNonRegionalOTR',
      multi: false,
      options: [
        {
          title: 'Yes',
          value: true,
        },
        { title: 'No', value: false },
      ],
    },
    {
      title: 'Maximum # of moving violations in the last 3 years',
      key: 'violations',
      clientPortalHint:
        'Select your company’s maximum allowable moving violations.',
      multi: false,
      options: [0, 1, 2, 3, 4, 5],
    },
    {
      title: 'Maximum # of accidents in the last 3 years',
      key: 'accidents',
      clientPortalHint: 'Select your company’s maximum allowable accidents.',
      multi: false,
      options: [0, 1, 2, 3, 4, 5],
    },

    {
      title: 'Are leads required to have any military experience?',
      multi: false,
      key: 'militaryExperience',
      options: [
        {
          title: 'Yes',
          value: true,
        },
        { title: 'No', value: false },
      ],
    },
    {
      title: 'Paid Orientation',
      key: 'paidOrientation',
      multi: false,
      category: 'benefits',
      options: [
        {
          title: 'Yes',
          value: true,
        },
        { title: 'No', value: false },
      ],
    },
    {
      title: 'Rider Program Spouse',
      key: 'riderSpouse',
      multi: false,
      category: 'benefits',
      options: [
        {
          title: 'Yes',
          value: true,
        },
        { title: 'No', value: false },
      ],
    },
    {
      title: 'Rider Program Child',
      key: 'riderChild',
      multi: false,
      category: 'benefits',
      options: [
        {
          title: 'Yes',
          value: true,
        },
        { title: 'No', value: false },
      ],
    },
    {
      title: 'Pets',
      key: 'petsAllowed',
      multi: false,
      category: 'benefits',
      options: [
        {
          title: 'Yes',
          value: true,
        },
        { title: 'No', value: false },
      ],
    },
  ];
};
